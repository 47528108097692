import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { vw, patterns, icons } from '../utilities/helpers'
import SEO from '../utilities/seo'

import Block from '../components/block'
import Bit from '../components/bit'
import Glow from '../components/glow'
import Menu from '../components/submenu'
import Line from '../components/line'
import Grid from '../components/grid'
import Next from '../components/next'
import Parallax from '../components/parallax'
import Body from '../components/wrap'
import Footer from '../components/footer'
import Text from '../components/text'

const Wrap = styled.section`
  padding-top: ${vw(2.5)};

  ${Line} {
    margin-bottom: ${vw(1)}
  }

  h1{
    padding-top: ${vw(0.5)};
  }
`

const nav = [
    {
        'key': 3,
        'label': 'Videos',
        'anchor': '#igdVideos'
    },
    {
        'key': 2,
        'label': 'Photos',
        'anchor': '#igdPhotos'
    },
    {
        'key': 1,
        'label': 'Reports',
        'anchor': '#igdReports'
    }
]

const videoList = []
const docList = []
const albumList = []

function renderVideos(items, index){   
    var node = items.node.data

    videoList[index] = {
        'key': index,
        'title': node.title.text,
        'subtitle': node.last_publication_date,
        'bg': patterns.green,
        'fg': node.link.thumbnail_url,
        'icon': icons.play,
        'video': node.link.embed_url
    }
}

function renderAlbums(items, index) {
    var node = items.node.data
    var photos = []
    var gallery = node.gallery

    gallery.forEach(function(ele, pos){
        photos[pos] ={
            'key': pos, 
            'url': ele.image.url        }
    })

    albumList[index] = {
        'key': index,
        'title': node.title.text,
        'subtitle': node.date,
        'bg': patterns.pink,
        'fg': gallery[0].image.url,
        'icon': icons.camera,
        'photos': photos
    }
}

function renderDocs(items, index) {
    var node = items.node.data

    docList[index] = {
        'key': index,
        'title': node.title.text,
        'href': node.link.url,
        'bg': patterns.green,
        'fg': node.cover.url,
        'icon': icons.doc
    }
}

export default ({data}) => {
  return (
    <>
    <main>
        <SEO title="Media" />
        
        <Menu items={nav} className="is-visible" />

        <Block>

            <Wrap>

              <Body id="igdVideos">
                  <h1>
                    <Text xlarge>Videos</Text>
                  </h1>
                  <Line/>
  
                  {data.allPrismicVideo.edges.map(renderVideos)}
                  <Grid col={4} cells={videoList}/>
              </Body>
  
              <Body id="igdPhotos">
                  <h1>
                    <Text xlarge>Photos</Text>
                  </h1>
                  <Line red/>
  
                  {data.allPrismicAlbum.edges.map(renderAlbums)}
                  <Grid col={4} cells={albumList} />
              </Body>
  
              <Body id="igdReports">
                  <h1>
                    <Text xlarge>Reports</Text>
                  </h1>
                  <Line />
  
                  {data.allPrismicDocument.edges.map(renderDocs)}
                  <Grid col={4} cells={docList} />
              </Body>
            </Wrap>

            <Glow royal top="-5%" left={vw(-4)} delay="0" />
            <Bit ring top="45%" left="15%" />
            <Glow cool top="15%" right={vw(-6)} delay="0.75" />
            <Bit arrow top="15%" right="15%" />
            <Bit cross top="45%" right="15%" />
            <Glow warm bottom="15%" left={vw(-6)} delay="0.75" />
            <Glow royal bottom="0" right={vw(-3)} delay="0" />
        </Block>

        <Next to="/register" title="Register" /> 

        <Parallax e />
    </main>
    <Footer />
    </>
  )
}

export const query = graphql`
query MediaQuery {
  allPrismicVideo {
    edges {
      node {
        data {
          link {
            embed_url
            thumbnail_url
          }
          title {
            text
          }
        }
        last_publication_date(formatString: "dddd DD MMMM YYYY HH:ma")
      }
    }
  }
  allPrismicAlbum {
    edges {
      node {
        data {
          date(formatString: "dddd DD MMMM YYYY HH:ma")
          title {
            text
          }
          venue {
            text
          }
          gallery {
            image {
              url
            }
          }
        }
      }
    }
  }
  allPrismicDocument {
    edges {
      node {
        data {
          cover {
            url
          }
          title {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
}
`